@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-slide > div {
  margin-left: 8px;
}
.slick-list {
  margin-left: -8px !important;
}

.slick-dots li {
  margin: 0 !important;
}

.slick-dots li button {
  padding: 0 !important;
}

.slick-dots li button:before {
  color: white !important;
}

.slick-prev::before,
.slick-next::before {
  content: none !important;
}

.slick-disabled {
  display: none !important;
}
